<template>
  <v-layout>
    <NavBar />
      <v-main>
              <router-view/>
              <v-snackbar 
                v-model="snackbar.show"
                :text="snackbar.message"
                 >

                  <template v-slot:actions>
                      <v-btn :color="snackbar.color" variant="text" @click="snackbar.show = false">
                          Close
                      </v-btn>
                  </template>
              </v-snackbar>
              </v-main>
              </v-layout>
              <v-layout>
              <v-footer class="bg-grey-lighten-1">
                <v-col class="font-weight-light" cols="auto" align-self="end">
                  <v-btn variant="text" to="terms">Terms and Conditions</v-btn>
                  <v-btn variant="text" to="privacy">Privacy Policy</v-btn>
                </v-col>

              </v-footer>
              </v-layout>

</template>

<script setup>
import NavBar from './components/NavBar.vue';
import { userStore } from '@/stores/UserStore';
import { storeToRefs } from 'pinia';
import { useNotificationsStore } from './stores/NotificationsStore';

const store = userStore()
store.load()

const notificationsStore = useNotificationsStore()
const { snackbar } = storeToRefs(notificationsStore)

</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
  text-align: center;
}

nav a {
  font-weight: bold;
  color: #bababa;
}

nav a.router-link-exact-active {
  color: #ffffff;
}

h1 {
  text-align: center;
}
</style>

