<template>
<v-app-bar color="blue-darken-4">
    <v-toolbar-title >
      <router-link style="color: aliceblue; text-decoration: none;" to="/">GovNow.ca</router-link>
    </v-toolbar-title>

    <template v-slot:append>
      <nav>
        <template v-if="store.user.signedIn">
          <v-btn variant="plain" to="/alerts">My Alerts</v-btn> | 
        </template>
        <template v-if="store.user.signedIn">
          <v-btn variant="plain" :title="store.user.email" v-on:click="store.signOut">Log out</v-btn>
        </template><template v-else>
          <v-btn variant="plain" to="/signin">Sign In/Create Free Account</v-btn>
        </template>
      </nav>
    </template>
  </v-app-bar>
</template>


<script setup>

import { userStore } from '@/stores/UserStore'

const store = userStore()

</script>