<template>

      <div  v-bind:style="{ 'background-image': 'url(/parliament.jpg)', 
                            'background-size':'cover',
                            'background-position': 'bottom right'}">
      <v-container>
    <v-row>
      <v-col sm="12" md="6">
        <v-card>
          <v-card-item>
        <v-card-title>Parliamentary monitoring. Made easy.</v-card-title>
        </v-card-item>
        <v-card-text>

        <p>GovNow.ca provides government relations, political, media and policy professionals with highly customizable tracking of the House of Commons and it's committees. Key features include:</p>
        <v-list lines="three">
        <v-list-item
          title="Completely custom tracking."
          subtitle="GovNow.ca empowers you to set alerts that can be as broad or narrow as you like. Only interested in AGRI? Done. Only want notices? Easy."
        ></v-list-item>
        <v-list-item
          title="Real-Time and daily alerts delivered to your inbox."
          subtitle="GovNow.ca is constantly checking for updates, but you decide what you want to know when."
        ></v-list-item>
      </v-list>
      <v-row  justify="center">
        <v-col cols="auto">

            <v-btn color="success" size="large" to="/signin">Sign Up Now - FREE</v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    </v-card>

      </v-col>
      </v-row>
      </v-container>
      </div>

</template>

<script setup>

</script>