import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { userStore } from '@/stores/UserStore'
import { useNotificationsStore } from '@/stores/NotificationsStore'
import { Hub} from 'aws-amplify';


const routes = [
  {
    path: '/',
    name: 'home',
    meta: { requiresAuth: false },
    component: HomeView
  },
  {
    path: '/signin',
    name: 'signin',
    meta: { requiresAuth: false },
    component: () => import(/* webpackChunkName: "secure" */ '../views/AuthView.vue')
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: () => import(/* webpackChunkName: "secure" */ '../views/AlertsView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    meta: { requiresAuth: false },
    component: () => import(/* webpackChunkName: "secure" */ '../views/PrivacyPolicyView.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    meta: { requiresAuth: false },
    component: () => import(/* webpackChunkName: "secure" */ '../views/TermsAndConditionsView.vue')
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router


router.beforeEach((to, from, next) => {
  const store = userStore()
  if (to.name === 'signin' && !store.user.signedIn) next()
  else if (to.meta.requiresAuth === false) next()
  else if (store.user.signedIn) next()
  else {
    const notificationsStore = useNotificationsStore()
    notificationsStore.showSnackbar("You must be signed in to access that page. Redirecting you to signin page.", "error")
    next({ name: 'signin' })
  
  }
})


    
Hub.listen('auth', (data) => {
  if (data.payload.event === 'signIn'){
    router.push({ name: 'alerts' })
  } else if (data.payload.event === 'signOut'){
    router.push({ name: 'home' })
  }
})