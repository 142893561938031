import { defineStore } from 'pinia'
import { Auth } from 'aws-amplify';

export const userStore = defineStore('user', {
    state: () => ({user: {email: null, signedIn: false}}),
    actions: {
      async load() {
        return await Auth.currentAuthenticatedUser({
          bypassCache: false
        }).then(cognitoUser => {
          this.user.email = cognitoUser.attributes.email
          this.user.signedIn = true
        })
        .catch(err => console.log(err));
      },
      async signIn(username, password) {
        await Auth.signIn(username, password)
        .then(user => {
          this.user.email = user.attributes.email
          this.user.signedIn = true
        })
        .catch(err => console.log(err));
      },
      async signOut() {
        await Auth.signOut()
        .then(data => {
          console.log(data)
          this.user.signedIn = false

        })
        .catch(err => console.log(err));
      }
    }
  })