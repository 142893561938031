import { defineStore } from 'pinia';

export const useNotificationsStore = defineStore('notifications', {
    state: () => ({
        snackbar: {
            message: '',
            color: '',
            show: false,
        },
    }),
    actions: {
        showSnackbar(message, color) {
            this.snackbar.message = message;
            this.snackbar.color = color;
            this.snackbar.show = true;
        },
        hideSnackbar() {
            this.snackbar.show = false;
        },
    },
});

