import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'

const pinia = createPinia()

import { Amplify, Auth} from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

Amplify.configure({
    API: {
      endpoints: [
        {
          name: "alerts",
          endpoint: process.env.VUE_APP_API_URL,
          custom_header: async () => { 
            return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }

          }
        },
        {
          name: "preview",
          endpoint: process.env.VUE_APP_API_URL,
          custom_header: async () => { 
            return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }

          }
        }
      ]
    }
  });

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    }
}})

import AmplifyVue from '@aws-amplify/ui-vue';

const app = createApp(App);
app.use(pinia)
app.use(vuetify)
app.use(router);
app.use(AmplifyVue);
app.mount('#app');
